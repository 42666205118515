import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import NavMobile from "../nav/NavMobile";
import I18n from "../../../i18n-js/index.js";
import { capitalizeFirstLetter } from "../../../helpers/base.js";
import Moment from "moment";
import { setFilterData } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import FiltersModal from "../filters/FiltersModal";
import axios from "axios";
import Search from "../filters/Search";
import SelectDate from "../components/dialogs/SelectDate.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AreaHeaderMobile(props) {
  const filtersData = useSelector((state) => state.filtersData);
  const filtersResults = useSelector((state) => state.filtersResults);
  const selectedAreaName = useSelector((state) => state.areaName);
  const [externalSubmit, setExternalSubmit] = useState(false);
  const [chosenDate, setChosenDate] = useState(null);
  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  I18n.locale = useSelector((state) => state.currentLocale);

  Moment.locale(I18n.locale);

  const handleClickOpen = () => {
    dispatch(setFilterData({ ...filtersData, open: true }));
  };

  const handleClose = () => {
    dispatch(setFilterData({ ...filtersData, open: false }));
  };

  const useStyles = makeStyles((theme) => ({
    header: {
      backgroundColor: "#f7eb67",
      position: "relative",
      width: "100%",
      height: "260px",
      padding: "90px 4vw 10px",
    },
    titleHeader: {
      fontSize: "20px",
      paddingBottom: "20px",
      fontWeight: "400 !important",
      textAlign: "left",
      backdropFilter: "blur(3px)",
      display: "block",
      borderRadius: "40px",
      width: "fit-content",
      color: "black",
    },
    filterButton: {
      position: "absolute",
      top: "200px",
      right: "10px",
      borderRadius: "20px",
      backgroundColor: "white",
      fontSize: "14px",
      textTransform: "none",
    },
    dateButton: {
      position: "absolute",
      top: "200px",
      left: "10px",
      width: "135px",
      borderRadius: "20px",
      fontSize: "14px",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 500,
    },
    paxButton: {
      position: "absolute",
      top: "200px",
      left: "140px",
      borderRadius: "20px",
      fontSize: "14px",
    },
    closeButton: {
      float: "right",
      marginTop: "-10px;",
    },
    buttonText: {
      color: "black",
      fontSize: "16px",
    },
    buttonTextRecommend: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      margin: "15px 40px 0 0",
      fontFamily: "TobiasTrial-SemiBold",
    },
    newButtonTextRecommend: {
      color: "#f7eb67",
      background: "black",
      padding: "2px",
      borderRadius: "4px",
      fontSize: "10px",
      marginRight: "2px",
    },
    emojis: {
      marginRight: "15px",
    },
    linkText: {
      fontWeight: "800 !important",
      fontSize: "14px",
      marginBottom: "-20px",
      borderRadius: 0,
      textTransform: "none !important",
    },
    secondaryHeroText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: "90%",
      marginBottom: "30px",
    },
    filterBtn: {
      marginTop: "-35px",
      float: "right",
      marginRight: "10px",
      background: "transparent !important",
      border: "none !important",
      borderLeft: "1px solid grey !important",
      boxShadow: "none !important",
      borderRadius: "0 !important",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <NavMobile source={"area_home"} areaName={props.areaName} />
        <SelectDate
          todayNotAvailable={props.todayNotAvailable}
          setExternalSubmit={setExternalSubmit}
          handleFiltersOpen={handleClickOpen}
          chosenDate={chosenDate}
          setChosenDate={setChosenDate}
          offset={offset}
        />
      <div className={classes.header}>
        {props.areaName && (
          <h1 className={classes.titleHeader}>
            {I18n.t("area_home.mobile_h1", {
              city: capitalizeFirstLetter(props.areaName),
            })}
          </h1>
        )}
        {filtersResults && filtersResults != undefined && (
          <React.Fragment>
            <div style={{ width: "100%" }}>
                <Search results={filtersResults} mobile_header={true} />
            </div>
            <SelectDate
              todayNotAvailable={props.todayNotAvailable}
              setExternalSubmit={setExternalSubmit}
              handleFiltersOpen={handleClickOpen}
              chosenDate={chosenDate}
              setChosenDate={setChosenDate}
              areaHeaderMobileMoving
            />
          </React.Fragment>
        )}

        {selectedAreaName == 'Mallorca' ?
          <a href="#tally-open=w8pVQO&tally-emoji-text=👋&tally-emoji-animation=wave" style={{top: "13px", position: "relative", textDecoration: "underline", color: "black"}}>
            <span className={classes.buttonTextRecommend}>
              <span className={classes.newButtonTextRecommend}>NEW</span>
              Recomiéndame experiencias
            </span>
          </a>
        : null}

        <FiltersModal
          open={open}
          alreadyFiltersResults={true}
          fullscreen={true}
          onClose={handleClose}
          source={"experiences"}
          available_date={chosenDate}
          todayNotAvailable={props.todayNotAvailable}
          externalSubmit={externalSubmit}
          areaHome
        />
      </div>
    </>
  );
}

export default AreaHeaderMobile;
