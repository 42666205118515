import React from "react";
import PropTypes from "prop-types";
import RoomBlock from "../roomblock";
import FilterBar from "../main/filters/FilterBar";
import { createMedia } from "@artsy/fresnel";
import {
  numDaysBetween,
  currencyToSymbol,
  capitalizeFirstLetter,
  setNullToFuture,
} from "../../helpers/base";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import loadingLogo from "images/logo_hotelbreak_mini.png";
import I18n from "../../i18n-js/index.js.erb";
import { to_eur } from "../../helpers/base";
import { toNumber } from "i18n-js";
import { useState, useEffect, useRef } from "react";
import NavMobile from "../main/nav/NavMobile";
import NavDesktop from "../main/nav/NavDesktop";
import { useSelector, useDispatch } from "react-redux";
import {
  addCSRFToken,
  setCurrentUser,
  changeLocale,
  changeSelectedArea,
  setAreaHomeProducts,
  changeSelectedAreaName,
  setAreaHomeHotelCategories,
} from "../redux/actions/actions";
import Maps from "../main/components/maps/maps";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import WidgetNav from "./WidgetNav";
import WidgetFooter from "./WidgetFooter";
import WidgetNavMobile from "./WidgetNavMobile";
import moment from "moment";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    lg: 1024,
  },
});



export default function WidgetRoomList(props) {
  const [closed_all_data, setClosed_all_data] = useState(
    props.closed_all_rooms
  );
  const [current_locale, setCurrent_locale] = useState(props.current_locale);
  const currentLocale = props.current_locale;
  const [loadFilter, setLoadFilter] = useState(false);
  const [all_rooms, setAll_rooms] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(2);
  const [orderBy, setOrderBy] = useState("all");
  const [ready, setReady] = useState(false);
  axios.defaults.headers.common["X-CSRF-Token"] = props.csrf_token;
  axios.defaults.withCredentials = true;

  const dispatch = useDispatch();
  const [mapVisible, setMapVisble] = useState(false);
  const [hoveredHotel, setHoveredHotel] = useState(false);
  const selectedArea = capitalizeFirstLetter(props.area);

  dispatch(addCSRFToken(props.csrf_token));

  const brand = props.brand?.data?.attributes;

  useEffect(() => {
    const WebFont = require('webfontloader')
    brand?.font &&
    WebFont.load({
      google: {
        families: [brand?.font, 'sans-serif']
      }
    })
  }, [brand?.font])

  useEffect(() =>{
    setReady(true)
  }, [brand])


  if (props.current_user && props.wallet && !ready) {
    dispatch(
      setCurrentUser({
        ...props.current_user,
        wallet: props.wallet
      }),
      changeLocale(props.current_locale)
    );
   } else {
    dispatch(changeLocale(props.current_locale))
  }

  const currentUser = useSelector((state) => state.currentUser);
  const selectedAreaName = useSelector((state) => state.areaName);
  const selectedDate = useSelector((state) => state.selectedDate) ?
                        useSelector((state) => state.selectedDate)
                      :
                        props.date ?
                          props.date
                        :
                          props.filterrific_params && props.filterrific_params.available_date ?
                            props.filterrific_params.available_date
                          :
                            null

  useEffect(() => {
    if (props.area && selectedArea) {
      dispatch(changeSelectedArea(selectedArea));
    } else if (props.area && !selectedArea) {
      dispatch(changeSelectedArea(props.area));
    }
    axios
      .get("/api/main/products", {
        params: { location: selectedArea, locale: currentLocale },
      })
      .then((response) => {
        dispatch(
          setAreaHomeProducts(
            response.data.area_home_products
              .map((product_list) => {
                return {
                  category: product_list.products.map(
                    (productSerialization) => productSerialization.attributes
                  ),
                  title: product_list.title,
                  slug: product_list.slug,
                  emoji: product_list.emoji,
                  popularity: product_list.popularity,
                  type: product_list.type
                };
              })
              .sort((a, b) => {
                if (a.popularity > b.popularity) {
                  return -1;
                } else if (
                  a.popularity === "most_popular" ||
                  a.popularity === "most_recent"
                ) {
                  return -1;
                } else {
                  return 1;
                }
              })
          )
        );
        dispatch(setAreaHomeHotelCategories(response.data.rooms_categories));
        dispatch(changeSelectedAreaName(response.data.province));
      });
  }, [selectedArea]);

  let infiniteStyle = {
    overflow: "visible",
    marginLeft: "-1.8rem",
    marginRight: "-1.8rem",
  };

  let rowStylesTitle = {
    marginBottom: "0",
  };

  let rowStylesBody = {
    marginTop: "0",
  };

  let removeRowStyle = {
    marginLeft: "0",
    marginRight: "0",
    marginBottom: "0",
  };

  let headings = {
    fontFamily: brand?.font ? brand?.font : 'inherit',
  };

  let textStyles = {
    fontSize: "20px",
    margin: "0.9rem 0 0 0",
    padding: "0 0 0 15px",
    fontFamily: brand?.font ? brand?.font : 'inherit',
  };

  let rightColumn = {
    width: '40%',
    height: '100%',
  }

  let smallerColumn = {
    maxWidth: mapVisible ? '61%' : 'inherit',
    margin: mapVisible && '0 0 0 1%',
  }

  I18n.locale = current_locale;

  useEffect(() => {
    setAll_rooms(props.all_rooms);

    if (props.all_rooms.length === 0) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, []);

  var fetchFilteredData = (orderby) => {
    setOrderBy(orderby);
    setHasMore(true);

    axios
      .get("/api/v1/roomlist/roomblocks.json", {
        params: {
          page_number: 1,
          filter: orderby,
          filterrific: props.filterrific_params,
          location: props.location,
          product: props.products,
          adults: props.adults,
          kids: props.kids,
          total: props.total,
          province: selectedArea,
          'wallet_friendly': props.wallet_friendly,
          'adults-only': props.adults_only,
          'all-inclusive': props.all_inclusive,
          'family-friendly': props.family_friendly,
          'most-popular': props.most_popular,
          'most-recent': props.most_recent,
          'date': selectedDate
        },
      })
    .then((response) => {
        setPageNumber(2);
        setAll_rooms(response.data);
        setLoadFilter(false);
      });
  };

  var getOrder = (orderBy) => {
    setAll_rooms([]);
    fetchFilteredData(orderBy);
  };

  var filterRooms = (orderBy) => {
    setLoadFilter(true);
    setAll_rooms([]);
    getOrder(orderBy);
  };

  var fetchMoreData = () => {
    setTimeout(function () {
      axios
        .get("/api/v1/roomlist/roomblocks.json", {
          params: {
            page_number: pageNumber,
            filter: orderBy,
            filterrific: props.filterrific_params,
            location: props.location,
            product: props.products,
            adults: props.adults,
            kids: props.kids,
            total: props.total,
            province: selectedArea,
            'wallet_friendly': props.wallet_friendly,
            'adults-only': props.adults_only,
            'all-inclusive': props.all_inclusive,
            'family-friendly': props.family_friendly,
            'most-popular': props.most_popular,
            'most-recent': props.most_recent,
            'date': selectedDate
          },
        })

        .then((response) => {
          setPageNumber(pageNumber + 1);
          setAll_rooms(all_rooms.concat(response.data));
          if (response.data.length === 0) {
            setHasMore(false);
          }
        });
    }, 500);
  };

  const getHoveredHotel = (hotelId) => {
    setHoveredHotel(hotelId);
  }

  const isMapShown = (e) => {
    setMapVisble(e)
  }

  const allRooms = () => {
    var content = [];

    var already_rendered_keys = [];
    var actual_index = -1;

    I18n.locale = current_locale;

    all_rooms.forEach((room, index) => {
      const roomblock = (
        <RoomBlock
          key={room.id}
          roomid={room.id}
          area={room.attributes.area.replace("-", " ").toUpperCase()}
          brand={brand}
          area={room.attributes.area.replace("-", " ").toUpperCase()}
          city={room.attributes.city}
          category={room.attributes.category.toLowerCase()}
          listing_name={room.attributes.hotelName}
          direct_booking={room.attributes.directBooking}
          block_photo={room.attributes.blockPhoto}
          is_closed_today={room.attributes.isClosed}
          is_new={ room.attributes.isNew }
          review_note={room.attributes.averageReview}
          star_rating={room.attributes.starRating}
          slug={room.attributes.slug}
          location={props.location}
          current_locale={current_locale}
          favoriteids={props.favoriteids}
          price_model={room.attributes.priceModel}
          price={to_eur(room.attributes.lowestPrice)}
          min_price_max_guest={room.attributes.minPriceMaxGuest}
          margin={room.attributes.margin}
          currency={currencyToSymbol(room.attributes.currency)}
          current_user={props.is_current_user}
          reviews_count={room.attributes.reviewsCount}
          index={actual_index + 1}
          product_categories={room.attributes.productCategories}
          available_date={selectedDate}
          timeDistance={room.attributes.timeDistance}
          hoveredHotelFunc={getHoveredHotel}

          widget
        ></RoomBlock>
      );

      if (!already_rendered_keys.includes(room.id)) {
        actual_index = actual_index + 1;

        if ((actual_index + 1) % 2 === 0) {
          content.push(
            <div className="row" style={removeRowStyle} key={room.id}>
              {roomblock}
            </div>
          );

        } else {
          if (actual_index + 1 === all_rooms.length) {
            content.push(
              <div className="row" style={removeRowStyle} key={room.id}>
                {roomblock}
              </div>
            );
          } else {
            content.push(roomblock);
          }
        }
      }
      already_rendered_keys.push(room.id);
    });
    return content;
  };

  const roomList = (device) => {
    const content = (
      <React.Fragment>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <FilterBar
            filterRooms={filterRooms}
            text_list={props.text_list}
            current_locale={current_locale}
            hotel_search__filter={props.hotel_search__filter}
            count_filters={props.count_filters}
            initial_filter={props.order_by}
            filterrific_params={props.filterrific_params}
            exp_var={props.exp_var}
            source={'hotels'}
            products={props.products}
            adults={props.adults}
            kids={props.kids}
            wallet_friendly={props.wallet_friendly}
            price_high={props.price_high}
            price_low={props.price_low}
            available_date={selectedDate}
            font={brand?.font}
            brand={brand}
            desktop={device == "desktop"}
          />

          <div className={clsx("container")} style={smallerColumn}>
            <div className="row" style={rowStylesTitle}>
              <div className="col-md-12">
                <h1
                  className={clsx("result_title__roomlist nodisplay_above768")}
                  style={textStyles}
                  dangerouslySetInnerHTML={{ __html: props.text_list }}
                ></h1>
              </div>
            </div>

            <div className="row" style={rowStylesBody}>
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="col-6">
                      {!loadFilter && (
                        <InfiniteScroll
                          dataLength={all_rooms.length}
                          next={fetchMoreData}
                          scrollThreshold={"0.65"}
                          hasMore={hasMore}
                          style={infiniteStyle}
                          loader={
                            <div className="loading__container">
                              <img
                                className="loading__hotelbreak"
                                alt={"Loading logo"}
                                src={loadingLogo}
                                data-original={loadingLogo}
                              />
                            </div>
                          }
                        >
                          {allRooms()}
                        </InfiniteScroll>
                      )}

                      {loadFilter && (
                        <div className="loading__container">
                          <img
                            className="loading__hotelbreak"
                            alt={"Loading logo"}
                            src={loadingLogo}
                            data-original={loadingLogo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WidgetFooter brand={brand} />
      </React.Fragment>
    )
    return content;
  }

  return (
    <div style={{ visibility: ready ? "visible" : "hidden" }}>
      <MediaContextProvider>
        <Media at="sm">
          <WidgetNavMobile brand={brand} location={'hotel'} />
          {roomList("mobile")}
        </Media>
        <Media at="md">
          <WidgetNavMobile brand={brand} location={'hotel'} />
          {roomList("mobile")}
        </Media>
        <Media greaterThanOrEqual="lg">
          <WidgetNav brand={brand} location={'hotel'} />
          {roomList("desktop")}
        </Media>
      </MediaContextProvider>
    </div>
  );
}
