import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "images/logo.svg";
import Button from "@material-ui/core/Button";
import Emoji from "a11y-react-emoji";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AreaCategoryNav from "./AreaCategoryNav";
import UserNav from "./UserNav";
import AreaNav from "./AreaNav";
import { useSelector, useDispatch } from "react-redux";
import { setDesktopUserLoginFormStatus, setCurrentUser } from "../../redux/actions/actions";
import I18n from "../../../i18n-js/index.js";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import SelectArea from "../components/dialogs/SelectArea";
import { useCookies } from 'react-cookie';

const TransitionZoom = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="left" ref={ref} {...props} />;
});

function NavMobile(props) {
  const dispatch = useDispatch();

  const selectedAreaName = useSelector((state) => state.areaName);
  const currentUser = useSelector((state) => state.currentUser);
  const currentLocale = I18n.currentLocale();
  const [menu, setMenu] = useState(props.menu);
  const [menuType, setMenuType] = useState("principal");
  const [open, setOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);

  const StyledDialog = withStyles({
    paper: {
      width: "120rem",
      height: "50rem",
    },
  })(Dialog);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserNav = () => {
    setMenuType("user");
  };

  const handleAreaNav = () => {
    setMenuType("area");
  };

  const handleIsMenu = () => {
    setMenuType("principal");
  };

  const handleUserSignOut = () => {
    removeCookie('jwt', { path: '/' });
    dispatch(
      setCurrentUser({
        id: false,
        role: false,
        isInformationComplete: { country: false, status: false },
        loggedIn: false,
      })
    );
    window.location.href = "/sign_out";
  };

  const handleMenu = () => {
    setMenu(true);
  };

  const handleMenuUser = () => {
    setMenu(true);
    setMenuType("user");
  };

  const menuClose = () => {
    setMenu(false);
    setMenuType("principal");
  };

  const handleUserLoginOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: true,
        register: false,
      })
    );
  };

  const handleUserRegisterOpen = () => {
    dispatch(
      setDesktopUserLoginFormStatus({
        open: true,
        signin: false,
        register: true,
      })
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      width: "100%",
      zIndex: "2",
      top: 0,
      backgroundColor: "#F7EB67",
    },
    rootNoFix: {
      top: "10px !important",
      zIndex: "2",
      background: "#F7EB67",
    },
    buttonTextLeft: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      top: "3px !important",
      position: "relative",
      fontFamily: "TobiasTrial-SemiBold",
      cursor: "pointer",
      "&:after": {
        content: " ' ' ",
        border: "solid black",
        borderWidth: "0 1px 1px 0",
        display: "inline-block",
        padding: "3px",
        marginLeft: "10px",
        marginBottom: "2px",
        transform: "rotate(45deg)",
      },
    },
    rootFull: {
      position: "fixed",
      width: "100vw",
      zIndex: "11",
      top: "0",
      background: "#F7EB67",
    },
    dialogRoot: {
      width: "87vw",
    },
    closeButton: {
      float: "right",
      marginTop: "-10px;",
    },
    buttonText: {
      color: "black",
      fontSize: "16px",
      "&:hover": {
        textDecoration: "none !important",
      },
    },
    userImage: {
      borderRadius: "100px",
      width: "20px",
      marginTop: "-4px !important",
      border: "2px solid rgba(0,0,0,0.8)",
    },
    menuButton: {
      padding: "12px 5px",
      position: "absolute",
      top: "10px",
      right: "0",
      color: "black",
      "&:hover": {
        textDecoration: "none",
      },
    },
    accountButton: {
      position: "absolute",
      right: "50px",
      padding: "12px 5px",
      color: "black",
      marginTop: "-46px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    logoBlock: {
      padding: "10px 20px",
      borderRadius: "20px",
      display: "block",
    },
    logo: {
      width: "120px",
    },
    loginButton: {
      backgroundColor: "#0098d7",
      color: "white",
      width: "100%",
      fontSize: "1.2rem",
    },
    signinButton: {
      backgroundColor: "#ffb400",
      color: "white",
      width: "100%",
      fontSize: "1.2rem",
    },
    areaSelector: {
      backgroundColor: "black",
      color: "white",
      fontSize: "30px",
      fontWeight: "800",
      marginLeft: "-24px",
      paddingLeft: "37px",
      width: "calc(100% + 48px)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.8)",
      },
    },
    emojis: {
      marginRight: "15px",
    },
    accountButtonIcon: {
      fontSize: "1.5rem",
    },
    travelAgenbar: {},
    buttonTextRecommend: {
      color: "black",
      fontSize: "14px",
      textTransform: "none !important",
      margin: "15px 40px 0 0",
      fontFamily: "TobiasTrial-SemiBold",
    },
    newButtonTextRecommend: {
      color: "white",
      background: "black",
      padding: "2px",
      borderRadius: "4px",
      fontSize: "10px",
      marginRight: "2px",
    },
  }));

  const classes = useStyles();

  return (
    <>
      {currentUser.role === "travel_agent" && (
        <div className={classes.travelAgenbar}>
          {I18n.t("travel_agent__navbar", {
            travel_agent_email: currentUser.email,
          })}{" "}
          |{" "}
          <a href="https://dashboard.hotelbreak.com">
            {I18n.t("travel_agent__navbar_link")}
          </a>
        </div>
      )}

      <div className={classes.rootFull} >
        <div className={classes.logoBlock}>
          {props.pay ? (
            <img className={classes.logo} src={logo} data-original={logo} />
          ) : (
            <>
              <a href={"/"}>
                <img className={classes.logo} src={logo} data-original={logo} />
              </a>
              <span
                className={classes.buttonTextLeft}
                onClick={
                  props.landing ? props.handleClickOpen : handleClickOpen
                }
              >
                {selectedAreaName ? selectedAreaName : I18n.t("select_city")}
              </span>
            </>
          )}
        </div>
        {!props.pay && (
          <>
            <Button
              className={classes.menuButton}
              onClick={handleMenu}
              disableRipple
            >
              <MenuIcon />
            </Button>
            {currentUser.role && (
              <Button
                className={classes.accountButton}
                onClick={handleMenuUser}
                disableRipple
              >
                {currentUser.image_url ? (
                  <img className={classes.userImage} src={currentUser.image_url} />
                ) : (
                  <i
                    className={clsx(
                      "fas fa-user-circle",
                      classes.accountButtonIcon
                    )}
                  ></i>
                )}
              </Button>
            )}
          </>
        )}
      </div>

      <Dialog
        open={menu}
        onClose={menuClose}
        fullScreen
        scroll={"paper"}
        TransitionComponent={TransitionZoom}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {menuType === "principal" && (
          <DialogTitle id="alert-dialog-title">
            <img className={classes.logo} src={logo} data-original={logo} />
            <IconButton
              className={classes.closeButton}
              edge="end"
              color="inherit"
              onClick={menuClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {menuType === "user" && (
          <DialogTitle id="alert-dialog-title">
            {I18n.t("my_account")}
            <IconButton
              className={classes.closeButton}
              edge="end"
              color="inherit"
              onClick={menuClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {menuType === "area" && (
          <DialogTitle id="alert-dialog-title" onClick={handleIsMenu}>
            <IconButton edge="start" color="inherit" aria-label="close">
              <ArrowBackIosIcon />
            </IconButton>
            {I18n.t("select_city")}
          </DialogTitle>
        )}
        {menuType === "principal" && (
          <DialogContent dividers={true}>
            <List component="nav" className={classes.dialogRoot}>
              {selectedAreaName == 'Mallorca' && currentLocale == 'es' ?
                <a href="#tally-open=w8pVQO&tally-emoji-text=👋&tally-emoji-animation=wave" style={{top: "-10px", position: "relative", textDecoration: "underline", color: "black"}}>
                  <span className={classes.buttonTextRecommend}>
                    <span className={classes.newButtonTextRecommend}>NEW</span>
                    Recomiéndame experiencias
                  </span>
                </a>
              : null}
              <ListItem
                button
                className={classes.areaSelector}
                onClick={handleAreaNav}
              >
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    {props.areaName ? props.areaName : I18n.t("select_city")}
                  </Grid>

                  <Grid item xs={2}>
                    <ArrowForwardIosIcon />
                  </Grid>
                </Grid>
              </ListItem>

              <br />

              <AreaCategoryNav />

              {currentUser.role ? (
                <ListItem
                  button
                  className={classes.buttonText}
                  onClick={handleUserNav}
                >
                  <Emoji symbol="👛" className={classes.emojis} />{" "}
                  {currentUser.fullname}
                </ListItem>
              ) : (
                <>
                  <a href={`/${currentLocale}/${I18n.t("routes.create_gift")}`}>
                    <ListItem button className={classes.buttonText}>
                      <Emoji symbol="💝" className={classes.emojis} />{" "}
                      <span className={classes.buttonText}>
                        {I18n.t("give_daycation")}
                      </span>
                    </ListItem>
                  </a>
                  <Divider />
                </>
              )}
            </List>
          </DialogContent>
        )}
        {menuType === "user" && <UserNav />}
        {menuType === "area" && <AreaNav />}
        <DialogActions>
          {currentUser.role === false && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  className={classes.loginButton}
                  onClick={handleUserLoginOpen}
                  variant="contained"
                >
                  {I18n.t("begin_session")}
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  className={classes.signinButton}
                  onClick={handleUserRegisterOpen}
                  variant="contained"
                >
                  {I18n.t("sign_up")}
                </Button>
              </Grid>
            </Grid>
          )}

          {currentUser.role !== false && (
            <Grid container>
              <Grid item xs={12}>
                <Button
                  className={classes.signinButton}
                  onClick={handleUserSignOut}
                  variant="contained"
                >
                  {I18n.t("logout")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <SelectArea handleClose={handleClose}></SelectArea>
      </StyledDialog>
    </>
  );
}

export default NavMobile;
