import React from "react";
import { useState, useRef } from "react";
import { useStylesRedeemVoucher } from "../styles/styles";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import I18n from "../../../../i18n-js/index.js.erb"
import Reward from 'react-rewards';
import { Col, InputGroup, Input, Button } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import Emoji from "a11y-react-emoji";
import { setAreaHomeProducts, addCSRFToken, setAreaHomeHotelCategories, } from "../../../redux/actions/actions";
import { capitalizeFirstLetter } from "../../../../helpers/base.js";
import { useSelector, useDispatch } from "react-redux";

const schema = yup.object({
  redeem_voucher: yup.string().required(I18n.t('is_required'))
})

function RedeemVoucherForm(props) {
  const classes = useStylesRedeemVoucher();
  const dispatch = useDispatch();

  const rewardRef = useRef();
  const rewardButtonRef = useRef();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [experienceUrl, setExperienceUrl] = useState();
  const [experiencesInAreaUrl, setExperiencesInAreaUrl] = useState();
  const [experiencePhoto, setExperiencePhoto] = useState();
  const [experienceName, setExperienceName] = useState();
  const [hotelName, setHotelName] = useState();
  const [area, setArea] = useState();
  const [gifter, setGifter] = useState();
  const [redeemedByUser, setRedeemedByUser] = useState(false);

  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
  
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser)
  
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["Authorization"] = `Bearer ${currentUser.token}`;

  const callReward = () => {
    setTimeout(() => {
      rewardRef.current.rewardMe();
    }, 100);
  };

  const callPunish = () => {
    setTimeout(() => {
      rewardButtonRef.current.punishMe();
    }, 100);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      overflow: "hidden",
      margin: "20px auto",
      padding: "0 10px",
    },
  }));

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setError(false);
    axios
      .post("/api/main/voucher_redemptions/create.json", { ...data, locale: currentLocale })
      .then((response) => {
        callReward();
        setSuccess(true);
        setWalletBalance(response.data.wallet_balance);
        setVoucherAmount(response.data.gift_voucher_amount);
        setExperienceUrl(response.data.room_url);
        setExperiencePhoto(response.data.product_photo);
        setExperienceName(response.data.product_name);
        setHotelName(response.data.hotel_name);
        setExperiencesInAreaUrl(response.data.experiences_url);
        setArea(response.data.area);
        setGifter(response.data.gifter)
      })
      .catch(function (error) {
        callPunish();
        setError(error.response.data.error_message)
        error.response.data.redeemed_by_user &&
          setRedeemedByUser(error.response.data.redeemed_by_user)
          setExperienceUrl(error.response.data.room_url);
          setExperiencePhoto(error.response.data.product_photo);
          setExperienceName(error.response.data.product_name);
          setHotelName(error.response.data.hotel_name);
          setExperiencesInAreaUrl(error.response.data.experiences_url);
          setArea(error.response.data.area);
          setGifter(error.response.data.gifter)
      });
  };

  const code = new URLSearchParams(window.location.search).get('code');

  return (
    <React.Fragment>
      <div className={clsx(classes.root)}>
        <h2>{I18n.t('new_gift__redeem_title')}</h2>
        <p>{I18n.t('new_gift__redeem_subtitle')}</p>
        <div className={classes.inner}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register('redeem_voucher')}
              name="redeem_voucher"
              id="redeem_voucher"
              className={clsx('form-control', (errors.redeem_voucher || error) && 'error')}
              placeholder={I18n.t('gift_code_placeholder')}
              value={code}
            />
            <p className={errors.redeem_voucher && 'error'}>{errors.redeem_voucher?.message}</p>
            <p className={error && 'error'}>{error}</p>
            {redeemedByUser &&
              <>
              <strong>{I18n.t("what_now")}</strong>
              <p>{I18n.t('try_added_to_wallet')}</p>
              <div dangerouslySetInnerHTML={{ __html: I18n.t("how_to_book_html") }} />
              <br /><br />
              {experienceUrl && experiencesInAreaUrl && gifter ?
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <img src={`https://www.hotelbreak.com/${experiencePhoto}`} style={{maxWidth: '250px'}}/>
                      </div>
                      <div className="col-md-6">
                        <strong>{experienceName}</strong>
                        <p>{hotelName}</p>
                        <br />
                        <a className={"btn btn-primary"} target="_blank" href={experienceUrl}><Emoji symbol="👉" /> {I18n.t('view_recommended_experience')}</a>
                        <br /><small>{I18n.t("explain_recommemded", {gifter: gifter})}</small>
                      </div>
                      <div className="col-md-12">
                        <br /><br />
                        <a className={"btn btn-primary"} target="_blank" href={experiencesInAreaUrl}><Emoji symbol="👀" /> {I18n.t('hotelbreak_in', {place: capitalizeFirstLetter(area)})}</a>
                      </div>
                    </div>
                  </>
                :
                  <>
                    <a className={"btn btn-primary"} target="_blank" href={`/${I18n.locale}/wallet`}><Emoji symbol="👉" /> {I18n.t('mailer_wallets_welcome__btntext')}</a>
                    <br /><br />
                    <a className={"btn btn-primary"} target="_blank" href={experiencesInAreaUrl}><Emoji symbol="👀" /> {I18n.t('view_all_experiences')}</a>
                  </>
              }
              </>
            }
            <br />
            {!success ?
              !redeemedByUser &&
            <Reward
              ref={rewardButtonRef}
            >
              <Button type="submit" className={classes.fullWidth}>{I18n.t('new_gift__redeem_button')}</Button>
            </Reward>
            :
              <>
                <Reward
                  ref={rewardRef}
                  type='confetti'
                >
                  <span className={clsx(classes.success, classes.fullWidth)}><Emoji symbol="✅" /> {I18n.t('new_gift__redeem_success')}</span>
                </Reward>
                <br /><br />
                <strong>{I18n.t("what_now")}</strong>
                <p>{I18n.t('added_to_wallet', {amount: to_eur(voucherAmount)})}</p>
                <div dangerouslySetInnerHTML={{ __html: I18n.t("how_to_book_html") }} />
                <br /><br />
                {experienceUrl && experiencesInAreaUrl && gifter ?
                    <>
                      <a className={"btn btn-primary"} target="_blank" href={experienceUrl}><Emoji symbol="👉" /> {I18n.t('view_recommended_experience')}</a>
                      <br /><small>{I18n.t("explain_recommemded", {gifter: gifter})}</small>
                      <br /><br />
                      <a className={"btn btn-primary"} target="_blank" href={experiencesInAreaUrl}><Emoji symbol="👀" /> {I18n.t('hotelbreak_in', {place: capitalizeFirstLetter(area)})}</a>
                    </>
                  :
                    <>
                      <a className={"btn btn-primary"} target="_blank" href={`/${I18n.locale}/wallet`}><Emoji symbol="👉" /> {I18n.t('mailer_wallets_welcome__btntext')}</a>
                      <br /><br />
                      <a className={"btn btn-primary"} target="_blank" href={`/${I18n.locale}/experiences`}><Emoji symbol="👀" /> {I18n.t('view_all_experiences')}</a>
                    </>
                }
                <br />
                <strong>
                  {/*I18n.t('redeem_success_sentence', {amount: to_eur(voucherAmount), total: to_eur(walletBalance)})*/}
                </strong>
              </>
            }
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RedeemVoucherForm;
